import AccordionGroup from '../../../components/AccordionGroup';
import { FormProvider, useForm } from 'react-hook-form';
import { useEffect, useRef, useState } from 'react';
import { Description, Label } from '../../../catalyst/fieldset';
import { Switch } from '../../../catalyst/switch';
import * as Headless from '@headlessui/react';
import ProductCard from '../../../components/ProductCard';
import DeleteRecipientModal from './DeleteRecipientNotification';
import ConfirmRecipientForm, { AddressFields } from './ConfirmRecipientForm';
import pick from 'lodash/pick';
import { recipientValuesDefault, useGiftForm } from '../GiftOrderContext';
import InputFormField from '../../../components/InputFormField';
import RecipientDetailsStatic from './RecipientDetailsStatic';
import MessageDetailsStatic from './MessageDetailsStatic';
import ValidateAddress from '../AddRecipientStep/ValidateAddress';
import Info from '../../../components/Info';
import { Badge } from '../../../catalyst/badge';
import GiftIncomingNotification from './GiftIncomingNotification';
import { fieldRequired } from '../helper';
import { OrderStatus } from '../../../constants/variable';

type FormValues = {
  first_name: string;
  last_name: string;
  company: string;
  phone: string;
  title: string;
  email: string;
  internal_id: string;
  address_1: string;
  address_2: string;
  city: string;
  state: string;
  zipcode: string;
  country: string;
  use_same_address: boolean;
  email_message: string;
};

type MessageFormValue = {
  email_message: string;
  printed_message: string;
};

const GiftOrderView = ({
  handleDeleteRecipient,
  setDisableSubmit,
  recipients,
  partialOrder,
}: any) => {
  const [editIndex, setEditIndex] = useState<number | undefined>();
  const [deleteIndex, setDeleteIndex] = useState<number | undefined>();
  const [showModal, setShowModal] = useState(false);
  const [isEditMode, setEditMode] = useState(false);
  const [addressValidateModal, setAddressValidateModal] = useState(false);
  const [isEditMessage, setEditMessage] = useState(false);

  const recipientData = useRef(recipientValuesDefault);
  const messageData = useRef({});

  const { giftProduct, messages, setMessages, setRecipients } = useGiftForm();

  const characterLimit = Number(giftProduct.char_count) || 200;
  const lineCountLimit = Number(giftProduct.line_count) || 4;

  const [printedMessageLength, setPrintedMessageLength] = useState(0);

  const methods: any = useForm<FormValues>({});

  const methodsMessage: any = useForm<MessageFormValue>({});

  useEffect(() => {
    if (isEditMessage) methodsMessage.reset(messages[0]);
  }, [isEditMessage]);

  const handleMessageEdit = (idx: number) => {
    setEditMessage(true);
    messageData.current = messages[0];
    setDisableSubmit(true);
  };

  const handleEditMessageCancel = () => {
    setMessages((prevState: any) => {
      prevState[0] = messageData.current;
      return prevState;
    });
    setEditMessage(false);
    setDisableSubmit(false);
  };

  const handleMessageSubmit = (data: any) => {
    if (methodsMessage.formState.isValid)
      setMessages((prevState: any) => {
        prevState[0] = data;
        return prevState;
      });
    setEditMessage(false);
    setDisableSubmit(false);
  };

  const handleBlur = (event: any) => {
    const fieldName = event.target.name;
    const fieldValue = event.target.value;
    methodsMessage.setValue(fieldName, fieldValue);
    methodsMessage.trigger();
  };

  const handleConfirmDelete = (indx: number) => {
    handleDeleteRecipient(indx);
    setDeleteIndex(undefined);
  };

  const handleEditClick = (idx: number) => {
    setEditIndex(idx);
    setEditMode(true);
    recipientData.current = recipients[idx];
    setDisableSubmit(true);
  };

  const handleEditCancel = () => {
    if (editIndex)
      setRecipients((prevState: any) => {
        prevState[editIndex] = recipientData.current;
        return prevState;
      });
    setEditMode(false);
    setEditIndex(undefined);
    setDisableSubmit(false);
    recipientData.current = recipientValuesDefault;
  };

  const handleRecipientSubmit = (data: any, recipientIndex: number) => {
    if (messages.length > 1) {
      setMessages((prevState: any) => {
        prevState[recipientIndex].printed_message = giftProduct.is_digital_gift
          ? ''
          : data.printed_message;
        prevState[recipientIndex].email_message = data.email_message;
        return prevState;
      });
    }

    setRecipients((prevState: any) => {
      prevState[recipientIndex] = data;
      return prevState;
    });

    validateAddress();
  };

  const submitForm = () => {
    setEditMode(false);
    setEditIndex(undefined);
    setDisableSubmit(false);
    recipientData.current = recipientValuesDefault;
  };

  const validateAddress = () => {
    setAddressValidateModal(true);
  };

  const setAddressValidatedInRecipient = (
    recipientIndex: number,
    addressValid: boolean,
    placeId: string,
    errorMessages: [],
    addressWarnings: []
  ) => {
    setRecipients((prevState: any) => {
      prevState[recipientIndex]['address_validated'] = addressValid;
      prevState[recipientIndex]['address_id'] = placeId;
      prevState[recipientIndex]['address_errors'] = errorMessages;
      prevState[recipientIndex]['address_warning'] = addressWarnings;

      if (recipientIndex === 0) {
        prevState = prevState.map((rec: any, index: number) => {
          if (rec['use_same_address'] && index !== 0) {
            rec['address_validated'] = addressValid;
            rec['address_id'] = placeId;
            rec['address_errors'] = errorMessages;
            rec['address_warning'] = addressWarnings;
          }
          return rec;
        });
      }
      return prevState;
    });
  };

  const setAddressInRecipients = (newAddress: any, recipientIndex: number, addressWarnings: []) => {
    setRecipients((prevState: any) => {
      prevState[recipientIndex] = {
        ...prevState[recipientIndex],
        ...newAddress,
        address_errors: [],
        address_warning: addressWarnings,
        address_validated: true,
      };

      if (recipientIndex === 0) {
        prevState = prevState.map((rec: any, index: number) => {
          if (rec['use_same_address'] && index !== 0) {
            rec = { ...rec, ...pick(prevState[0], AddressFields), address_validated: true, address_errors: [], address_warning: addressWarnings };
          }
          return rec;
        });
      }
      return prevState;
    });
  };

  const keepAddress = (recipientIndex: number, addressValid: boolean, placeId: string, errorMessages: [], addressWarnings: []) => {
    if (typeof editIndex === 'number' && editIndex > -1)
      setAddressValidatedInRecipient(editIndex, addressValid, placeId, errorMessages, addressWarnings);
    setAddressValidateModal(false);
    submitForm();
  };

  const changeAddress = (newAddress: any, recipientIndex: number, addressWarnings: []) => {
    if (typeof editIndex === 'number' && editIndex > -1)
      setAddressInRecipients(newAddress, editIndex, addressWarnings);
    setAddressValidateModal(false);
    submitForm();
  };

  const numberOfLinesExceeded = (value: string) => {
    const lines = value.split('\n');
    return lines.length > lineCountLimit;
  };

  const handlePrintedMessageChange = (ev: any) => {
    let text = ev.target.value;
    if (text.length > characterLimit) {
      text = text.slice(0, characterLimit);
    }
    methodsMessage.clearErrors('printed_message');
    setPrintedMessageLength(text.length);
    methodsMessage.setValue('printed_message', text);
  };

  const getErrorMessagePartial = (errorObject: any) => {
    if (errorObject?.code === 422) {
      return errorObject.error;
      // To be reverted when BE is fixed
      // return errorObject.error.join(', ');
    } else if (errorObject?.code === 500) {
      return errorObject.message;
    } else {
      return 'Could not recognise error, please re-order';
    }
  };

  const recipientsOrderError = recipients.filter(
    (recipient: any) => recipient.orderStatus === OrderStatus.ERROR,
  );
  const recipientsOrderSuccess = recipients.filter(
    (recipient: any) => recipient.orderStatus !== OrderStatus.ERROR,
  );

  return (
    <div className='flex flex-row justify-center gap-10'>
      <div className='grow-0 basis-2/5'>
        <div>
          <AccordionGroup title='Gifts' titleClass='text-xl font-semibold'>
            <ProductCard title={giftProduct.title} imageURL={giftProduct?.thumbnail_url}>
              <p>
                <span className='text-sm font-medium'>{`${recipientsOrderError.length} selected`}</span>
              </p>
            </ProductCard>
          </AccordionGroup>
          {messages.length === 1 && (
            <AccordionGroup
              key={messages[0].email_message}
              title={'Message'}
              isEdit={!isEditMessage && !isEditMode}
              editEnabled={isEditMessage}
              handleEdit={handleMessageEdit}
              titleClass='text-xl font-semibold'
              listLocation={1}
              handleSave={methodsMessage.handleSubmit((d: any) => handleMessageSubmit(d))}
              handleCancel={handleEditMessageCancel}
            >
              <div>
                {!isEditMessage ? (
                  <MessageDetailsStatic
                    messages={messages}
                    index={0}
                    isDigitalGift={giftProduct?.is_digital_gift}
                  />
                ) : (
                  <form
                    id={`message_${0}`}
                    key={`message_${0}`}
                    onSubmit={methodsMessage.handleSubmit(handleMessageSubmit)}
                  >
                    {!giftProduct?.is_digital_gift && (
                      <div className='my-3'>
                        <InputFormField
                          name='printed_message'
                          label='Printed Message'
                          register={methodsMessage.register}
                          errors={methodsMessage.formState.errors}
                          inputType={'textarea'}
                          validation={{
                            required: 'This field is required',
                            validate: {
                              charLimit: (value: string) =>
                                !numberOfLinesExceeded(value) ||
                                  `The gift has ${lineCountLimit} lines limitation for this note`,
                              present: fieldRequired
                            }
                          }}
                          onChange={handlePrintedMessageChange}
                          isRequiredLabel={false}
                        />
                        <p>
                          {characterLimit > printedMessageLength
                            ? characterLimit - printedMessageLength
                            : 0}{' '}
                          characters left
                        </p>
                      </div>
                    )}
                    <InputFormField
                      name='email_message'
                      label='Your email message'
                      register={methodsMessage.register}
                      errors={methodsMessage.errors}
                      onBlur={handleBlur}
                      inputType={'textarea'}
                      validation={{
                        required: giftProduct?.is_digital_gift || 'This field is required',
                        validate: { present: fieldRequired }
                      }}
                      isRequiredLabel={false}
                    />
                  </form>
                )}
                <Headless.Field className='contianer mt-8 flex items-center gap-1'>
                  <div className='flex flex-row'>
                    <div className='mt-2 basis-1/5'>
                      <Switch
                        name={`message.${0}.incoming_email`}
                        checked={giftProduct.is_digital_gift ? true : messages[0].incoming_email}
                        color='sky'
                        onChange={(event) => {
                          if (!giftProduct.is_digital_gift)
                            setMessages((prevState: any) => {
                              const newMessage = [...prevState];
                              newMessage[0].incoming_email = !prevState[0].incoming_email;
                              return newMessage;
                            });
                        }}
                      />
                    </div>
                    <GiftIncomingNotification
                      onCheckboxChange={(event: any) => {
                        if (!giftProduct.is_digital_gift) {
                          setMessages((prevState: any) => {
                            const newMessage = [...prevState];
                            newMessage[0].incoming_email = !prevState[0].incoming_email;
                            return newMessage;
                          });
                        }
                      }}
                      checked={giftProduct.is_digital_gift ? true : messages[0].incoming_email}
                    />
                  </div>
                </Headless.Field>
              </div>
            </AccordionGroup>
          )}
        </div>
      </div>
      <div className={messages.length > 1 ? 'basis-2/3' : 'basis-1/2'}>
        <div>
          {recipientsOrderError.map((recipient: any, index: number) => (
            <AccordionGroup
              key={recipient.email}
              id={'confirm_recipient' + index}
              title={
                'Recipient ' + (index + 1) + ' ' + recipient.first_name + ' ' + recipient.last_name
              }
              handleDelete={(num: number) => {
                setDeleteIndex(num);
                setShowModal(true);
              }}
              isDelete={recipientsOrderError.length > 1}
              isEdit={!isEditMode && !isEditMessage}
              editEnabled={editIndex === index}
              listLocation={index + 1}
              handleEdit={(index: number) => handleEditClick(index)}
              handleSave={methods.handleSubmit((d: any) => handleRecipientSubmit(d, index))}
              handleCancel={handleEditCancel}
            >
              {editIndex !== index && (
                <div key={index} className='container'>
                  <RecipientDetailsStatic recipient={recipient} product={giftProduct} />
                  <div>
                    {messages.length > 1 && (
                      <MessageDetailsStatic
                        messages={messages}
                        index={index}
                        isDigitalGift={giftProduct?.is_digital_gift}
                      />
                    )}
                  </div>
                </div>
              )}
              {editIndex === index && (
                <div className='container'>
                  <FormProvider {...methods}>
                    <form
                      id={`recipient_${index}`}
                      key={`recipient_${index}`}
                      onSubmit={methods.handleSubmit(handleRecipientSubmit)}
                    >
                      <ConfirmRecipientForm
                        recipientIndex={index}
                        recipients={recipients}
                        isMessageForm={messages.length > 1}
                        isDigital={giftProduct.is_digital_gift}
                      />
                    </form>
                  </FormProvider>
                </div>
              )}
              {messages.length > 1 && (
                <div className='container'>
                  <Headless.Field className='contianer mt-8 flex items-center'>
                    <div className='flex flex-row'>
                      <div className='mt-2 basis-16'>
                        <Switch
                          name={`message.${index}.incoming_email`}
                          checked={messages[index].incoming_email}
                          color='sky'
                          onChange={(event) => {
                            setMessages((prevState: any) => {
                              const newMessage = [...prevState];
                              newMessage[index].incoming_email = !prevState[index].incoming_email;
                              return newMessage;
                            });
                          }}
                        />
                      </div>
                      <GiftIncomingNotification
                        onCheckboxChange={(event: any) => {
                          setMessages((prevState: any) => {
                            const newMessage = [...prevState];
                            newMessage[index].incoming_email = !prevState[index].incoming_email;
                            return newMessage;
                          });
                        }}
                        checked={messages[index].incoming_email}
                      />
                      <div className='grow-0'>
                        <Label>Gift incoming email</Label>
                        <Description>
                          {`The initial notification is off as you're sending a custom note. To send it turn this toggle on. `}
                          <span>
                            <a href='#'>Learn more about notifications here.</a>
                          </span>
                        </Description>
                      </div>
                    </div>
                  </Headless.Field>
                </div>
              )}
              <div className='mt-2'></div>
              <Info color='red'>
                {getErrorMessagePartial(
                  partialOrder?.find((order: any) => order.uuid === recipient.uuid),
                )}
              </Info>
            </AccordionGroup>
          ))}
          {addressValidateModal && typeof editIndex === 'number' && (
            <ValidateAddress
              recipient={recipientsOrderError[editIndex]}
              recipientIndex={editIndex}
              changeAddress={changeAddress}
              keepAddress={keepAddress}
              closeModal={() => setAddressValidateModal(false)}
            />
          )}
        </div>
        <div>
          {recipientsOrderSuccess.map((recipient: any, index: number) => (
            <AccordionGroup
              key={recipient.email}
              id={'confirm_recipient' + index}
              title={'Recipient ' + ' ' + recipient.first_name + ' ' + recipient.last_name}
              titleBadge={
                <Badge color='greenTxtWht' className='ml-2'>
                  Order sent
                </Badge>
              }
              handleDelete={(num: number) => {
                setDeleteIndex(num);
                setShowModal(true);
              }}
            >
              <div key={index} className='container'>
                <RecipientDetailsStatic recipient={recipient} product={giftProduct} />
                <div>
                  {messages.length > 1 && (
                    <MessageDetailsStatic
                      messages={messages}
                      index={index}
                      isDigitalGift={giftProduct?.is_digital_gift}
                    />
                  )}
                </div>
              </div>

              {messages.length > 1 && (
                <div className='container'>
                  <Headless.Field className='contianer mt-8 flex items-center'>
                    <div className='flex flex-row'>
                      <div className='mt-2 basis-16'>
                        <Switch
                          name={`message.${index}.incoming_email`}
                          checked={messages[index].incoming_email}
                          color='sky'
                          onChange={(event) => {
                            setMessages((prevState: any) => {
                              const newMessage = [...prevState];
                              newMessage[index].incoming_email = !prevState[index].incoming_email;
                              return newMessage;
                            });
                          }}
                          disabled
                        />
                      </div>
                      <GiftIncomingNotification
                        disabled={true}
                        checked={messages[index].incoming_email}
                        onCheckboxChange={(event: any) => {
                          setMessages((prevState: any) => {
                            const newMessage = [...prevState];
                            newMessage[index].incoming_email = !prevState[index].incoming_email;
                            return newMessage;
                          });
                        }}
                      />
                    </div>
                  </Headless.Field>
                </div>
              )}
            </AccordionGroup>
          ))}
        </div>
      </div>
      {showModal && (
        <DeleteRecipientModal
          setShowModal={setShowModal}
          deleteRecipient={handleConfirmDelete}
          index={deleteIndex}
        />
      )}
    </div>
  );
};

export default GiftOrderView;
