import { useState, useEffect } from 'react';
import { useGiftForm } from '../GiftOrderContext';
import DigitalGiftOrder from './DigitalGiftOrder';
import PhysicalGiftOrder from './PhysicalGiftOrder';
import Modal from '../../../components/ModalRevisited';
import axios from 'axios';
import Footer from '../Footer';
import { Button } from '../../../components/Button';
import { useNavigate } from 'react-router-dom';
import { omit } from 'lodash';
import GiftOrderView from './GiftOrderViewPartial';
import { GENERAL_ENQUIRY_FORM_URL, ORDER_ENQUIRY_FORM_URL, OrderStatus } from '../../../constants/variable';
import { v4 as uuidv4 } from 'uuid';

const ConfirmOrder = ({ currentStep, handleBack, handleNextStep }: any) => {
  const navigate = useNavigate();

  const [disableSubmit, setDisableSubmit] = useState();

  const [partialOrderResp, setPartailOrderResp] = useState([]);

  const baseUrl = process.env.REACT_APP_BASE_URL;

  const token = localStorage.getItem('ACCESS_TOKEN');

  const { recipients, giftProduct, messages, order, setRecipients, setMessages, setOrder } = useGiftForm();

  const [loading, setLoading] = useState(false);
  const [currentIndex, setCurrentIndex] = useState<number | undefined>(undefined);

  const { is_digital_gift } = giftProduct;

  const userData = JSON.parse(localStorage.getItem('userData') || '{}');

  const submitOrderStatus = order.status;

  const setSubmitOrderStatus = (status: string) => {
    setOrder({ status: status });
  }

  const handleDeleteRecipient = (index: number) => {
    if (recipients.length > 1) {
      setRecipients((prevState: any) =>
        [].concat(prevState.filter((_: any, indx: number) => indx !== index)),
      );
      if (messages.length > 1)
        setMessages((prevState: any) =>
          [].concat(prevState.filter((_: any, indx: number) => indx !== index)),
        );
    }
  };

  useEffect(() => {
    setRecipients((prevState: any) => {
      prevState = prevState.map((recipient: any, index: number) => {
        const messageData = messages[index] || messages[0];
        recipient = { ...recipient, ...messageData, uuid: uuidv4() };
        return recipient;
      });
      return prevState;
    });
  }, [messages]);

  const getRecipientsError = (partialOrder: any) => {
    const filteredRecepients = recipients.reduce((acc: any[], recipient: any) => {
      if (
        partialOrder.some(
          (order: any) =>
            order.status === OrderStatus.ERROR && order?.uuid === recipient?.uuid
        )
      ) {
        recipient.orderStatus = OrderStatus.ERROR;
        acc.push(recipient);
      }
      return acc;
    }, []);
    return filteredRecepients;
  };

  const getRecipientsSuccess = (partialOrder: any) => {
    const filteredRecepients = recipients.reduce((acc: any[], recipient: any) => {
      if (
        partialOrder.some(
          (order: any) =>
            order.status !== OrderStatus.ERROR && order?.uuid === recipient?.uuid
        )
      ) {
        recipient.orderStatus = OrderStatus.SUCCESS;
        acc.push(recipient);
      }
      return acc;
    }, []);
    return filteredRecepients;
  };

  const prepareData = (data: any) => {
    const updatedRecipients = !partialOrderResp.length
      ? data.recipients
      : data.recipients.filter((recipient: any) => recipient.orderStatus !== OrderStatus.SUCCESS);
    const payload = updatedRecipients.map((recipient: any, index: number) => {
      const messageData = messages[index] || messages[0];
      return { ...omit(recipient, ['address_errors']), ...messageData, address_warning: recipient['address_warning'].concat(recipient['address_errors']).join(', ') };
    });

    return {
      recipients: payload,
      product_id: giftProduct.id,
      product_title: giftProduct.title,
      image_url: giftProduct.image_url,
      thumbnail_url: giftProduct.thumbnail_url,
      is_digital_gift: giftProduct.is_digital_gift,
      customer_id: userData.id,
    };
  };

  const submitOrder = async (payload: any) => {
    setLoading(true);
    try {
      await axios
        .post(baseUrl + '/api/orders/placeOrder', payload, {
          headers: {
            Authorization: `Bearer ${token}`,
          },
        })
        .then(async (response) => {
          if (response.status === 201) {
            setSubmitOrderStatus('success');
            await axios
              .get(baseUrl + `/api/users/me`, {
                headers: {
                  Authorization: `Bearer ${token}`,
                },
              })
              .then((response) => {
                return response.data;
              })
              .then((data) => {
                localStorage.setItem('userData', JSON.stringify(data.data));
                window.dispatchEvent(new Event('storage'));
              });
          } else setSubmitOrderStatus('error');
          setLoading(false);
          setPartailOrderResp([]);
        });
    } catch (error: any) {
      setPartailOrderResp([]);
      if (error?.response?.status === 422) {
        const errorData = error?.response?.data?.data;
        setSubmitOrderStatus(OrderStatus.PARTIAL);
        setPartailOrderResp(errorData);
        setRecipients([...getRecipientsError(errorData), ...getRecipientsSuccess(errorData)]);
      } else {
        setSubmitOrderStatus(OrderStatus.ERROR);
      }
      if (error?.response?.data?.status === 401) {
        localStorage.removeItem('userData');
        localStorage.removeItem('ACCESS_TOKEN');
        window.location.href = '/login';
        window.location.reload();
      }
      setLoading(false);
    }
  };

  const onSubmit = () => {
    if (loading) return;
    const data = { recipients, giftProduct, messages };
    const orderData = prepareData(data);
    submitOrder(orderData);
  };

  const contactSupport = () => {
    window.open(GENERAL_ENQUIRY_FORM_URL, "_blank");
  };

  const dashboardPath = () => {
    if (userData.role === "USER") {
      return `/user/order`
    } else {
      return `/admin/order`
    }
  }

  return (
    <>
      <div className='mt-4 flex justify-center'>
        {/* Add your component content here */}
        {!submitOrderStatus && submitOrderStatus !== OrderStatus.PARTIAL && is_digital_gift && (
          <DigitalGiftOrder
            recipients={recipients}
            giftProduct={giftProduct}
            messages={messages}
            setRecipients={setRecipients}
            handleDeleteRecipient={handleDeleteRecipient}
            setDisableSubmit={setDisableSubmit}
          />
        )}
        {!submitOrderStatus &&
          submitOrderStatus !== OrderStatus.PARTIAL &&
          !is_digital_gift && ( // Add this line
            <PhysicalGiftOrder
              recipients={recipients}
              giftProduct={giftProduct}
              message={messages}
              setRecipients={setRecipients}
              handleDeleteRecipient={handleDeleteRecipient}
              setDisableSubmit={setDisableSubmit}
            />
          )}

        {submitOrderStatus === OrderStatus.PARTIAL && (
          <GiftOrderView
            handleDeleteRecipient={handleDeleteRecipient}
            setDisableSubmit={setDisableSubmit}
            recipients={recipients}
            partialOrder={partialOrderResp}
          />
        )}
        {!!currentIndex && (
          <Modal isOpen={!!currentIndex} onClose={() => setCurrentIndex(undefined)}>
            <div className='fles flex-row justify-center'>
              <div className='w-4/5 text-center'>
                <div>
                  <img src='/assets/newspaper.svg' alt='Delete warning' />
                </div>
                <h2>Delete Recipient confirmation</h2>
                <p>
                  Warning: This action is irreversible. All data will be removed. Are you sure you
                  want to proceed
                </p>
                <button onClick={() => setCurrentIndex(undefined)}>Cancel and go back</button>
                <button
                  onClick={() => {
                    handleDeleteRecipient(currentIndex - 1);
                    setCurrentIndex(undefined);
                  }}
                >
                  Remove recipient
                </button>
              </div>
            </div>
          </Modal>
        )}

        {!!submitOrderStatus && submitOrderStatus === OrderStatus.SUCCESS && (
          <div className='flex min-h-screen flex-row justify-center'>
            <div className='w-2/3 text-center'>
              <div className='item-center flex justify-center'>
                <img src='/assets/success.svg' />
              </div>
              <div className='mt-5 text-xl font-bold text-gray-800'>
                Gift have been successfully sent!
              </div>
              <div className='mt-5 text-base font-medium text-gray-500'>
                You will recieve an email confirmation and we will keep you posted about the status
                of the order
              </div>
              <div className='mt-5 flex justify-around'>
                <Button
                  type='outlineGreen'
                  onClick={() => navigate(dashboardPath())}
                >
                  Go back to home page
                </Button>
                <Button type='solidGreen' onClick={() => navigate(0)}>
                  Send another gift
                </Button>
              </div>
            </div>
          </div>
        )}
        {!!submitOrderStatus && submitOrderStatus === OrderStatus.ERROR && (
          <div className='flex min-h-screen flex-row justify-center'>
            <div className='w-2/3 text-center'>
              <div className='item-center flex justify-center'>
                <img src='/assets/wrong.svg' />
              </div>
              <div className='mt-5 text-xl font-bold text-gray-800'>Oops! Something went wrong</div>
              <div className='mt-5 text-base font-medium text-gray-500'>
                {`We're working on it! Apologies for the inconvenience. Hang tight or try reloading for a quick fix`}
              </div>
              <div className='mt-5 flex justify-around'>
                <Button
                  type='outlineGreen'
                  onClick={() => navigate(dashboardPath())}
                >
                  Go back to home page
                </Button>
                <Button type='solidGreen' onClick={() => navigate(0)}>
                  Send another gift
                </Button>
              </div>
              <div className='mt-5'>
                <Button type='link' onClick={() => contactSupport()}>
                  Contact Support
                </Button>
              </div>
            </div>
          </div>
        )}
      </div>
      {(!submitOrderStatus || submitOrderStatus === OrderStatus.PARTIAL) && (
        <Footer
          currentStep={currentStep}
          handleBack={handleBack}
          handleNextStep={onSubmit}
          isNextDisabled={disableSubmit || loading}
          partialOrder={!!partialOrderResp.length}
        />
      )}
    </>
  );
};

export default ConfirmOrder;
