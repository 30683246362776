import { useNavigate } from 'react-router-dom';
import { Helmet } from 'react-helmet';
import Wrapper from '../components/Wrapper';
import axios from 'axios';
import { Button } from '../catalyst/button';
import { useForm } from 'react-hook-form';
import InputFormField from '../components/InputFormField';
import { useState } from 'react';
import { useToast } from '../components/Toast/ToastProvider';

const EMAIL_REGEX = /[a-zA-Z0-9.\-+_]+@([a-zA-Z0-9-_]{1,63}\.)+[a-zA-Z]{1,63}/;

function Login() {
  const baseUrl = process.env.REACT_APP_BASE_URL;
  const [msg, setMsg] = useState(false);
  const toast = useToast();
  const {
    handleSubmit,
    register,
    formState: { errors },
    getValues,
    setError,
  } = useForm();
  const navigate = useNavigate();

  const getUser = async () => {
    const token = localStorage.getItem('ACCESS_TOKEN');
    const res = await axios
      .get(baseUrl + `/api/users/me`, {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      })
      .then((response) => {
        return response.data;
      })
      .then((data) => {
        localStorage.setItem('userData', JSON.stringify(data.data));
        if (data.data.role == 'ADMIN' || data.data.role == 'MANAGER') {
          navigate('/admin/order');
        } else {
          navigate('/user/order');
        }
        window.location.reload();
      })
      .catch((error) => {
        if (error?.response?.data?.status === 401) {
          localStorage.removeItem('userData');
          localStorage.removeItem('ACCESS_TOKEN');
          window.location.href = '/login';
          window.location.reload();
        } else if (error?.response?.data?.status === 400) {
          toast?.pushError(error?.response?.data?.error, 5000);
        }

      });
  };

  async function handleSubmitClick() {
    const payload = {
      email: getValues().email.trim().toLowerCase(),
      password: getValues().password,
    };

    const res = await axios
      .post(baseUrl + '/api/users/login', payload)
      .then(function (response) {
        if (response.status === 200) {
          localStorage.setItem('ACCESS_TOKEN', response.data.data.token);
          getUser();
        } else if (response.status === 204) {
          setError('root.serverError', {
            type: '400',
          });
        } else {
          setError('root.serverError', {
            type: '400',
          });
        }
      })
      .catch(function (error) {
        if (error?.response?.data?.status === 400) {
          toast?.pushError(error?.response?.data?.error, 5000);
        }
        setError('email', {
          type: '400',
        });
        setError('password', {
          type: '400',
        });
        setMsg(true);
      });
  }

  function redirectForgot() {
    navigate('/forgot-password');
  }

  return (
    <>
      <Helmet>
        <meta name="robots" content="noindex, nofollow" />
      </Helmet>
      <Wrapper className='flex flex-col items-center justify-center'>
        <div className='flex min-h-screen items-center justify-center'>
          <div className='flex h-24 items-center justify-between rounded-lg'>
            <div className='flex flex-col px-4'>
              <img src='/assets/order-delivered.svg' alt='mySvgImage' />
            </div>
            <div className='grid justify-between gap-6'>
              <div className='flex justify-center px-4 text-2xl font-semibold'> Login</div>
              {msg && (
                <div className='mt-2 text-center text-xs text-red-500'>
                  These credentials do not match our records.
                </div>
              )}
              <form onSubmit={handleSubmit(handleSubmitClick)}>
                <div className='flex min-w-96 flex-col p-4'>
                  <InputFormField
                    id='email'
                    label='Your work email'
                    name='email'
                    type='text'
                    register={register}
                    validation={{
                      required: 'This field is required',
                      pattern: {
                        value: EMAIL_REGEX,
                        message: 'Please enter a valid email address',
                      },
                    }}
                    errors={errors}
                    isRequired={true}
                    isRequiredLabel={false}
                  />
                </div>
                <div className='flex min-w-96 flex-col p-4'>
                  <InputFormField
                    id='password'
                    label='Your Password'
                    name='password'
                    type='password'
                    register={register}
                    validation={{
                      required: 'This field is required',
                    }}
                    errors={errors}
                    isRequired={true}
                    isRequiredLabel={false}
                  />
                </div>
                <div className='flex min-w-24 justify-center p-4'>
                  <Button
                    type='submit'
                    color='blue'
                    className='m-2 h-12 w-48 cursor-pointer rounded-lg px-6 text-base'
                  >
                    Login to my account
                  </Button>
                </div>
              </form>

              <div
                style={{ color: '#6B7280' }}
                className='flex cursor-pointer justify-center'
                onClick={() => redirectForgot()}
              >
                Forgot your password?
              </div>
            </div>
          </div>
        </div>
      </Wrapper>
    </>
  );
}

export default Login;
